import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Tooltip, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import InfoIcon from '@material-ui/icons/Info';
import cx from 'classnames';
import { reaction } from 'mobx';
import { useObserver } from 'mobx-react';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/trash-can 2.svg';
import { authSelectors } from '../../../../../state/ducks/auth';
import { DocumentRevision, DocumentRevisionStatus, RevisionChangeType } from '../../../../../state/ducks/documentRevisions/types';
import { relatedPartsActions } from '../../../../../state/ducks/relatedParts';
import { COLOR_TYPES, ListItemEdited, RelatedPartsListItem, RELATED_PARTS_STATUS, RELATED_TYPE } from '../../../../../state/ducks/relatedParts/types';
import AlertDialog from '../../../../app/alert.dialog/AlertDialog';
import Dialog from '../../../../components/dialogs';
import { toastError } from '../../../../components/notifications';
import Text from '../../../../components/Text';
import { documentVersionPath } from '../../../../document.revision/utils/paths';
import { FBButton, FBTooltip } from '../../../../form.builder';
import useActionCreator from '../../../../hooks/useActionCreator';
import useAsync from '../../../../hooks/useAsync';
import useDialog from '../../../../hooks/useDialog';
import { isDocumentRevisionReleased } from '../../../helpers/documentRevisionStatus';
import { getDynamicTextValue, getReplacedMessage, RELATED_TYPE_VALUES_ENUM } from './helper';
import useStyles from './RelatedPart.styles';
import RelatedPartsState from './RelatedParts.state';
import RelatedPartsQuickViewDialog from './relatedPartsQuickViewDialog';
import RelatedPartsTableHeader from './RelatedPartsTableHeader';

const ParentPartSelector: React.FunctionComponent<{
  status: RELATED_PARTS_STATUS
  parentStatusChanged: (value: string, oldSelection?: string) => void
  relatedPartsState: RelatedPartsState
  documentRevision: DocumentRevision
  relatedType: RELATED_TYPE
}> = ({
  status,
  parentStatusChanged,
  relatedPartsState,
  documentRevision,
  relatedType,
}) => {
  const [selectedOption, setSelectedOption] = useState(status);
  const [previousOption, setPreviousOption] = useState(status);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const classes = useStyles();
  const email = useSelector(authSelectors.currentUserEmail);
  const isOwner = documentRevision?.owner ? documentRevision?.owner?.user.email === email : true;
  const isDisabled = (documentRevision?.version > 1 && documentRevision.previousRevRelatedPartsStatus
    && documentRevision.previousRevRelatedPartsStatus !== RELATED_PARTS_STATUS.PARENT_NO)
    || ![DocumentRevisionStatus.Draft, DocumentRevisionStatus.PendingChange].includes(documentRevision.status)
    || !isOwner
    || relatedPartsState.viewOnlyMode;

  const subPartStatusCheckAction = useActionCreator(relatedPartsActions.subPartStatusCheck);
  const subPartStatusCheckAsync = useAsync({
    onSuccess: (newOption) => {
      switchRadioSelection(newOption);
      setShowConfirmDialog(true);
    },
    onError: (errMsg) => {
      if (errMsg) {
        toastError(errMsg);
        setSelectedOption(previousOption);
      }
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOption: RELATED_PARTS_STATUS = event.target.value as RELATED_PARTS_STATUS;

    if (relatedPartsState.viewOnlyMode) {
      return;
    }

    if (newOption !== selectedOption) {
      if (selectedOption !== RELATED_PARTS_STATUS.PARENT_NO) {
        if (selectedOption === RELATED_PARTS_STATUS.PARENT_SEPARATE) {
          subPartStatusCheckAsync.start(subPartStatusCheckAction,
            documentRevision.id, newOption, subPartStatusCheckAsync);
        } else {
          switchRadioSelection(newOption);
          setShowConfirmDialog(true);
        }
      } else {
        switchRadioSelection(newOption);
        parentStatusChanged(newOption);
      }
    }
  };

  const switchRadioSelection = (newOption) => {
    setPreviousOption(selectedOption);
    setSelectedOption(newOption);
  };

  const handleConfirm = (confirmedOption) => {
    setShowConfirmDialog(false);
    parentStatusChanged(confirmedOption, previousOption);
  };

  const handleCancel = () => {
    setSelectedOption(previousOption);
    setShowConfirmDialog(false);
  };

  const confirmMessages = {
    [RELATED_PARTS_STATUS.PARENT_TOGETHER]: 'relatedParts.parentPart.confirm.message.together',
    [RELATED_PARTS_STATUS.PARENT_SEPARATE]: 'relatedParts.parentPart.confirm.message.separate',
    [RELATED_PARTS_STATUS.PARENT_NO]: 'relatedParts.parentPart.confirm.message.no',
  };

  const isParentNoSelected = selectedOption === RELATED_PARTS_STATUS.PARENT_NO;
  const isParentTogetherSelected = selectedOption === RELATED_PARTS_STATUS.PARENT_TOGETHER;
  const isParentSeparateSelected = selectedOption === RELATED_PARTS_STATUS.PARENT_SEPARATE;

  return (
    <div>
      <div className={classes.title}>
        <Typography
          className={cx(classes.titleHeading, {
            [classes.smallText]: relatedPartsState.viewOnlyMode,
          })}
          variant="body1"
          id="tableTitle"
        >
          <Text translation="relatedParts.parentPart.question"
            values = {{
              relatedType: getDynamicTextValue(
                relatedType, RELATED_TYPE_VALUES_ENUM.PART, RELATED_TYPE_VALUES_ENUM.DOCUMENT),
            }} />
        </Typography>
        <Tooltip title={getReplacedMessage(
          'relatedParts.parentPart.tooltip', '{relatedType}',
          getDynamicTextValue(relatedType, RELATED_TYPE_VALUES_ENUM.PART, RELATED_TYPE_VALUES_ENUM.DOCUMENT))}
        placement="right"
        classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
        arrow>
          <InfoIcon color="primary" />
        </Tooltip>
      </div>
      <FormControl className={cx({
        [classes.smallText]: relatedPartsState.viewOnlyMode,
      })}>

        <RadioGroup row value={selectedOption} onChange={handleChange}
          id="partTypeSelector"
        >
          <FormControlLabel
            value={RELATED_PARTS_STATUS.PARENT_NO}
            control={<Radio color="primary" disableRipple={true} />}
            label={<Text translation="relatedParts.parentPart.answer.no" />}
            checked={isParentNoSelected}
            disabled={isDisabled}
          />
          <FormControlLabel
            value={RELATED_PARTS_STATUS.PARENT_TOGETHER}
            control={<Radio color="primary" disableRipple={true} />}
            label={<Text translation="relatedParts.parentPart.answer.yes.together"
              values = {{
                relatedType: getDynamicTextValue(
                  relatedType, RELATED_TYPE_VALUES_ENUM.SUB_PART, RELATED_TYPE_VALUES_ENUM.RELATED_DOCUMENTS),
              }} />
            }
            checked={isParentTogetherSelected}
            disabled={isDisabled}
          />
          <FormControlLabel
            value={RELATED_PARTS_STATUS.PARENT_SEPARATE}
            control={<Radio color="primary" disableRipple={true} />}
            label={<Text translation="relatedParts.parentPart.answer.yes.separate"
              values = {{
                relatedType: getDynamicTextValue(
                  relatedType, RELATED_TYPE_VALUES_ENUM.SUB_PART, RELATED_TYPE_VALUES_ENUM.RELATED_DOCUMENTS),
              }} />
            }
            checked={isParentSeparateSelected}
            disabled={isDisabled}
          />
        </RadioGroup>
      </FormControl>

      <Dialog
        open={showConfirmDialog}
        title="relatedParts.confirm.selection"
        confirmLabel="common.confirm"
        cancelLabel="common.cancel"
        onConfirm={() => handleConfirm(selectedOption)}
        onCancel={handleCancel}
        onClose={handleCancel}
      >
        <Text
          translation="relatedParts.confirm.message"
          values={{
            currentOption: getReplacedMessage(
              confirmMessages[selectedOption], '{relatedType}', getDynamicTextValue(
                relatedType, RELATED_TYPE_VALUES_ENUM.SUB_PART, RELATED_TYPE_VALUES_ENUM.RELATED_DOCUMENTS)),
            previous: getReplacedMessage(
              confirmMessages[previousOption], '{relatedType}', getDynamicTextValue(
                relatedType, RELATED_TYPE_VALUES_ENUM.SUB_PART, RELATED_TYPE_VALUES_ENUM.RELATED_DOCUMENTS)),
            relatedType: getDynamicTextValue(
              relatedType, RELATED_TYPE_VALUES_ENUM.SUB_PART_PLURALIZED, RELATED_TYPE_VALUES_ENUM.SUB_DOCUMENT_PLURALIZED),
          }}
        />
      </Dialog>
    </div>
  );
};

const RelatedPartGrid: React.FunctionComponent<{
  documentRevision: DocumentRevision
  createNewDocument: (details?: {docId: string, name: string}) => void
  viewRelatedPart: (docRevId: string, documentId: string) => void
  handleRemove: (docRevId: string) => void
  handleUpdate: (docRevId: string, suffixNumber: string, suffixName: string) => void
  fetchRelatedParts
  voidRelatedPart
  relatedPartsState: RelatedPartsState
  relatedType: RELATED_TYPE
}> = ({
  documentRevision,
  createNewDocument,
  viewRelatedPart,
  handleRemove,
  handleUpdate,
  fetchRelatedParts,
  voidRelatedPart,
  relatedPartsState,
  relatedType,
}) => {
  const classes = useStyles();

  const [isEditRowVisible, setIsEditRowVisible] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const { status } = documentRevision;

  const isReleased = isDocumentRevisionReleased(status);

  const showEditRow = () => {
    setIsEditRowVisible(true);
    setShowButton(false);
  };

  useEffect(() => {
    fetchRelatedParts(documentRevision.id);
    setShowButton(!relatedPartsState.viewOnlyMode);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentRevision.id, relatedPartsState.viewOnlyMode]);

  useEffect(() => reaction(
    () => relatedPartsState.relatedPartsList.length,
    () => {
      setIsEditRowVisible(false);
      setShowButton(true && !relatedPartsState.viewOnlyMode);
    },
  ), [relatedPartsState.relatedPartsList.length, relatedPartsState.viewOnlyMode]);

  const cancelEdit = () => {
    setIsEditRowVisible(false);
    setShowButton(true);
  };

  const setActivePartsFilter = (state: boolean) => () => relatedPartsState.setShowOnlyActiveSubParts(state);

  return useObserver(() => (
    <div className={classes.partsGrid}
      data-cy="parts-grid">
      <div>
        <Grid className={classes.gridCenterAlign} container>
          <Grid item>
            <Typography variant="h6" id="tableTitle" component="div">
              <Text translation="relatedParts.grid.title"
                values = {{
                  relatedType: getDynamicTextValue(
                    relatedType, RELATED_TYPE_VALUES_ENUM.PART_CAPS, RELATED_TYPE_VALUES_ENUM.DOCUMENT_CAPS),
                }} />
            </Typography>
          </Grid>

          { (showButton || isReleased)
              && <Grid item className={classes.buttonGroup}>
                { !isReleased && <Typography>
                  <Button
                    className={classes.btnMargin}
                    color="primary"
                    startIcon={<AddCircleIcon color={isReleased ? COLOR_TYPES.DISABLED : COLOR_TYPES.PRIMARY} />}
                    onClick={showEditRow}
                    disabled={isReleased}
                    data-cy="add-related-part-btn"
                  >
                    <Text translation="relatedParts.button.addText"
                      values = {{
                        relatedType: getDynamicTextValue(
                          relatedType, RELATED_TYPE_VALUES_ENUM.PART, RELATED_TYPE_VALUES_ENUM.DOCUMENT),
                      }} />
                  </Button>
                </Typography> }
                <div className={classes.filterOptions}>
                  <span>
                    <Text translation="relatedParts.text.show" />:&nbsp;
                  </span>
                  <span className={cx(relatedPartsState.showOnlyActiveSubParts
                    ? classes.normalFontWeight : classes.boldFontWeight)}
                  onClick={setActivePartsFilter(false)}
                  >
                    <Text translation="common.all" />
                  </span>
                  <span> {' | '} </span>
                  <span className={cx(relatedPartsState.showOnlyActiveSubParts
                    ? classes.boldFontWeight : classes.normalFontWeight)}
                  onClick={setActivePartsFilter(true)}
                  >
                    <Text translation="common.active" />
                  </span>
                </div>
              </Grid>
          }
        </Grid>
        <Divider className={classes.dividerStyle} />
      </div>
      <div>
        <RelatedPartList
          rootPartName= {documentRevision.name}
          rootRevisionChangeType = {documentRevision.revisionChangeType}
          {
            ...{
              documentRevision,
              voidRelatedPart,
              viewRelatedPart,
              handleRemove,
              handleUpdate,
              relatedPartsState,
              isReleased,
              relatedType,
            }
          } />
        {isEditRowVisible
          && <RelatedPartEditRow documentRevision={documentRevision}
            createNewDocument={createNewDocument}
            cancelEdit={cancelEdit}
            relatedPartsState={relatedPartsState}
          />
        }
        {
          (showButton && !isReleased) && <Button
            className={classes.fullLengthButton}
            variant="outlined"
            startIcon={<AddCircleIcon color={isReleased ? COLOR_TYPES.DISABLED : COLOR_TYPES.PRIMARY} />}
            onClick={showEditRow}
            data-cy="add-related-part-btn"
            disabled={isReleased}
          >
            <Text translation="relatedParts.button.addText"
              values = {{
                relatedType: getDynamicTextValue(
                  relatedType, RELATED_TYPE_VALUES_ENUM.PART, RELATED_TYPE_VALUES_ENUM.DOCUMENT),
              }} />
          </Button>
        }
      </div>
    </div>
  ));
};

const RelatedPartList: React.FunctionComponent<{
  voidRelatedPart: (listItem: RelatedPartsListItem, revisionChangeType: RevisionChangeType) => void
  viewRelatedPart: (docRevId: string, documentId: string) => void
  handleRemove: (docRevId: string) => void
  handleUpdate: (docRevId: string, suffixNumber: string, suffixName: string) => void
  rootPartName: string
  rootRevisionChangeType: RevisionChangeType
  relatedPartsState: RelatedPartsState
  documentRevision: DocumentRevision
  isReleased: boolean
  relatedType: RELATED_TYPE
}> = ({
  voidRelatedPart,
  viewRelatedPart,
  handleRemove,
  handleUpdate,
  rootPartName,
  rootRevisionChangeType,
  relatedPartsState,
  documentRevision,
  isReleased,
  relatedType,
}) => {
  const classes = useStyles();

  const editRelatedPart = (listItem: RelatedPartsListItem) => {
    if (!relatedPartsState.viewOnlyMode && !isReleased) {
      relatedPartsState.setListItemBeingEdited(listItem);
    }
  };

  const cancelLocalEdit = () => {
    relatedPartsState.clearListItemBeingEdited();
  };

  return useObserver(() => (
    <div className={classes.fullWidth}>
      <div aria-label="related parts table">
        <RelatedPartsTableHeader />
        <div>
          {relatedPartsState.relatedSubParts.map((listItem: RelatedPartsListItem) => (
            <>
              {
                relatedPartsState.listItemBeingEdited.docRevId === listItem.documentRevisionId
                  ? <RelatedPartEditRow
                    documentRevision={documentRevision}
                    cancelEdit={cancelLocalEdit}
                    handleRemove={handleRemove}
                    handleUpdate={handleUpdate}
                    relatedPartsState={relatedPartsState}
                    listItemBeingEdited={relatedPartsState.listItemBeingEdited}
                  />
                  : <RelatedPartViewRow
                    key={listItem.documentRevisionId}
                    {
                      ... {
                        listItem,
                        voidRelatedPart,
                        editRelatedPart,
                        viewRelatedPart,
                        rootPartName,
                        rootRevisionChangeType,
                        relatedPartsState,
                        isReleased,
                        relatedType,
                      }
                    }
                  />
              }
            </>
          ))}
        </div>
      </div>
    </div>
  ));
};

const RelatedPartViewRow: React.FunctionComponent<{
  listItem: RelatedPartsListItem
  voidRelatedPart: (listItem: RelatedPartsListItem, revisionChangeType: RevisionChangeType) => void
  editRelatedPart: (listItem: RelatedPartsListItem) => void
  viewRelatedPart: (docRevId: string, documentId: string) => void
  rootPartName: string
  rootRevisionChangeType: RevisionChangeType
  relatedPartsState: RelatedPartsState
  isReleased: boolean
  relatedType: RELATED_TYPE
}> = ({
  listItem,
  voidRelatedPart,
  editRelatedPart,
  viewRelatedPart,
  rootPartName,
  rootRevisionChangeType,
  relatedPartsState,
  isReleased,
  relatedType,
}) => {
  const classes = useStyles();
  const [isHover, setIsHover] = useState(false);
  const isVoided = listItem.status === DocumentRevisionStatus.Obsolete
  || listItem.status === DocumentRevisionStatus.Voided;

  const isRevChangeTypeObs = listItem.revisionChangeType === RevisionChangeType.Obsolete;
  const isStatusObsolete = listItem.status === DocumentRevisionStatus.Obsolete;

  const lastIndexOfDash = listItem.rootDocId.lastIndexOf('-');

  const confirmationDialog = useDialog();

  const confirmAction = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    voidRelatedPart(listItem, isRevChangeTypeObs ? rootRevisionChangeType : RevisionChangeType.Obsolete);
    confirmationDialog.close();
  };

  const cancelAction = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    confirmationDialog.close();
  };

  const titleCellRef = useRef<HTMLSpanElement>(null);
  const { scrollHeight, clientHeight } = (titleCellRef?.current ? titleCellRef?.current : {}) as HTMLSpanElement;

  const relatedSubPartDialog = useDialog();

  const openConfirmDialogHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    confirmationDialog.open();
  };

  const openDialogHandler = (e: React.MouseEvent<SVGElement>) => {
    e.stopPropagation();
    relatedSubPartDialog.open();
  };

  const linkHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const handleOpenNewTab = () => {
    viewRelatedPart(listItem.documentRevisionId, listItem.documentId);
  };

  const docIdTitle = () =>
    <span>{listItem.rootDocId.slice(0, lastIndexOfDash)} -
      <b>{listItem.rootDocId.substring(lastIndexOfDash + 1)}</b></span>;

  return (
    <>
      <div
        onClick={() => editRelatedPart(listItem)}
        className={cx(classes.itemRow, {
          [classes.voidedBackground]: isVoided,
        })}
        data-cy="related-parts-view-row"
      >
        <div className={cx(classes.tableCellOne, classes.relatedPartViewRowCell)}
          data-cy="view-row-docid-cell"
        >
          <Link
            to={documentVersionPath(listItem.documentRevisionId, listItem.documentId)}
            target="_blank"
            className={classes.link}
            onClick={linkHandler}
          >
            {docIdTitle()}
          </Link>
        </div>
        <div className={cx(classes.tableCellTwo, classes.relatedPartViewRowCell)}>
          <Tooltip title={
            <span>
              {isVoided ? listItem.name
                : <>{rootPartName} {listItem.suffixName && '-'} <b>{listItem.suffixName}</b></>}
            </span>
          }
          disableHoverListener={scrollHeight <= clientHeight}
          placement="bottom"
          classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
          arrow >
            <span ref={titleCellRef} className={classes.relatedPartRowTitleCell} data-cy="view-row-title-cell">
              {isVoided ? listItem.name
                : <>{rootPartName} {listItem.suffixName && '-'} <b>{listItem.suffixName}</b></>}
            </span>
          </Tooltip>
        </div>
        <div className={classes.tableCellThree} data-cy="obsolete-checkbox">
          { (listItem.version && listItem.version > 1)
            ? <>
              {
                isStatusObsolete
                  ? <span className={classes.OBS}>OBS</span>
                  : <Checkbox
                    checked = {isVoided || isRevChangeTypeObs}
                    disabled={relatedPartsState.viewOnlyMode || isReleased}
                    onClick={openConfirmDialogHandler}
                  />
              }
              {confirmationDialog.isOpen && (
                <AlertDialog
                  dialog={confirmationDialog}
                  confirmAction={confirmAction}
                  cancelAction={cancelAction}
                  message={isRevChangeTypeObs ? 'relatedParts.void.bringBack' : 'relatedParts.void.alert'}
                />
              )}
            </>
            : !isVoided
          && <FBTooltip title={getReplacedMessage(
            'relatedParts.checkbox.hover.msg', '{relatedType}',
            getDynamicTextValue(relatedType, RELATED_TYPE_VALUES_ENUM.SUB_PART_CAPS, RELATED_TYPE_VALUES_ENUM.SUB_DOCUMENT_CAPS))}
          placement="top" arrow
          classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
          >
            <Box
              onClick={(e) => e.stopPropagation()}
              className={classes.defaultCursor}>
              <Checkbox
                disabled
                classes={{ disabled: classes.disabled }}
              />
            </Box>
          </FBTooltip>
          }
        </div>
        <Box
          className={cx(classes.tableCellFour, classes.relatedPartEyeRowCell)} data-cy="view-icon"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <FBTooltip title="relatedParts.created.modal.quick.view" placement="top" arrow
            classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
          >
            <FontAwesomeIcon
              icon={isHover ? solid('eye') : regular('eye') }
              className={cx(classes.viewIcon, {
                [classes.viewIconHover]: isHover,
              })}
              onClick={openDialogHandler}
            />
          </FBTooltip>
        </Box>
      </div>
      {relatedSubPartDialog.isOpen && (
        <RelatedPartsQuickViewDialog
          documentId={listItem.documentId}
          documentRevisionId={listItem.documentRevisionId}
          onDialogClose={relatedSubPartDialog.close}
          onNewTabOpen={handleOpenNewTab}
          isOpen ={relatedSubPartDialog.isOpen}
        />
      )}
    </>
  );
};

const RelatedPartEditRow: React.FunctionComponent<{
  documentRevision: DocumentRevision
  listItemBeingEdited?: ListItemEdited
  createNewDocument?: (details?: {docId: string, name: string}) => void
  cancelEdit: () => void
  handleRemove?: (docRevId: string) => void
  handleUpdate?: (docRevId: string, suffixNumber: string, suffixName: string) => void
  relatedPartsState: RelatedPartsState

}> = ({
  documentRevision,
  listItemBeingEdited,
  createNewDocument,
  cancelEdit,
  handleRemove,
  handleUpdate,
  relatedPartsState,
}) => {
  const [relatedPartDetails, setRelatedPartDetails] = React.useState({
    docId: listItemBeingEdited?.docId ?? '',
    name: listItemBeingEdited?.name ?? '',
  });

  const skipId = listItemBeingEdited?.docId ?? '';

  const classes = useStyles();

  const showDeleteButton = listItemBeingEdited?.version === 1 && listItemBeingEdited?.docRevId !== '';

  const resetAndCancelEdit = () => {
    setRelatedPartDetails({ docId: '', name: '' });
    relatedPartsState.resetDocIdErrorMsg();
    cancelEdit();
  };

  const confirmEdit = () => {
    if (relatedPartsState.docIdErrorMsg) {
      return;
    }

    if (listItemBeingEdited?.docRevId && handleUpdate) {
      if (validateDocId()) {
        handleUpdate(listItemBeingEdited?.docRevId, relatedPartDetails.docId, relatedPartDetails.name);
      }
    } else {
      if (validateDocId() && createNewDocument) {
        createNewDocument(relatedPartDetails);
      }
    }
  };

  const titleCellRef = useRef<HTMLLabelElement>(null);
  const { scrollHeight, clientHeight } = (titleCellRef?.current ? titleCellRef?.current : {}) as HTMLLabelElement;

  const validateDocId = () => (
    relatedPartsState.validateDocId(documentRevision?.document?.docId, relatedPartDetails.docId, skipId)
  );

  return useObserver(() => (
    <>
      <div className={cx(classes.editRowParentDiv, {
        [classes.editRowParentNormal]: !relatedPartsState.docIdErrorMsg,
        [classes.editRowParentError]: Boolean(relatedPartsState.docIdErrorMsg),
      })}
      data-cy="related-parts-edit-row"
      >
        <div className={classes.editRowChildDivOne}>
          <label >{documentRevision?.document?.docId} - </label>
          <TextField
            className={classes.textField}
            disabled={listItemBeingEdited?.version ? listItemBeingEdited.version > 1 : false}
            variant="outlined"
            onChange = {(e: ChangeEvent<HTMLInputElement>) =>
              setRelatedPartDetails({ ...relatedPartDetails, docId: e.target.value })}
            onBlur = {validateDocId}
            value={relatedPartDetails.docId}
            error = {Boolean(relatedPartsState.docIdErrorMsg)}
            helperText={relatedPartsState.docIdErrorMsg ? <Text message={relatedPartsState.docIdErrorMsg} /> : ''}
            name="relatedPartDocId"
          />
        </div>
        <div className={classes.editRowChildDivTwo} data-cy="edit-row-title-cell">
          <Tooltip title={
            <label>{documentRevision?.name} - </label>
          }
          disableHoverListener={scrollHeight <= clientHeight}
          placement="bottom"
          classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
          arrow >
            <label ref={titleCellRef} className={classes.relatedPartRowTitleCell}>{documentRevision?.name} - </label>
          </Tooltip>
          <TextField
            className={classes.textField}
            variant="outlined"
            value = {relatedPartDetails.name}
            onChange = {(e: ChangeEvent<HTMLInputElement>) =>
              setRelatedPartDetails({ ...relatedPartDetails, name: e.target.value })}
            name="relatedPartTitle"
          />
        </div>
      </div>
      <div className={classes.actionButtonParentDiv} data-cy="edit-row-actions">
        <div className={classes.actionButtonChildDiv}>
          { showDeleteButton
          && <Button
            data-cy="delete-related-part-sub-part"
            className={classes.actionButton}>
            <DeleteIcon className={classes.deleteIconButton}
              onClick={() => handleRemove?.(listItemBeingEdited?.docRevId)} />
          </Button> }
          <Button
            data-cy="cancel-related-part-sub-part-addition"
            className={classes.actionButton} onClick = {resetAndCancelEdit}>
            <ClearIcon className={classes.iconButton} />
          </Button>
          <Button
            data-cy="confirm-related-part-sub-part-addition"
            className={classes.actionButton}
            onClick = {confirmEdit}>
            <DoneIcon className={classes.iconButton} />
          </Button>
        </div>
      </div>
    </>
  ));
};

const RelatedParts: React.FunctionComponent<{
  documentRevision: DocumentRevision
  parentStatus?: RELATED_PARTS_STATUS
  parentStatusChanged: (value: any) => void
  createNewDocument: (relatedPartDetails?: any) => void
  viewRelatedPart: (docRevId: string, documentId: string) => void
  handleRemove: (docRevId: string) => void
  handleUpdate: (docRevId: string, suffixNumber: string, suffixName: string) => void
  fetchRelatedParts: (documentId: any) => void
  voidRelatedPart: (listItem: RelatedPartsListItem, revisionChangeType: RevisionChangeType) => void
  relatedPartsState: RelatedPartsState
  relatedType: RELATED_TYPE
}> = ({
  documentRevision,
  parentStatus = RELATED_PARTS_STATUS.PARENT_NO,
  parentStatusChanged,
  createNewDocument,
  viewRelatedPart,
  handleRemove,
  handleUpdate,
  fetchRelatedParts,
  voidRelatedPart,
  relatedPartsState,
  relatedType,
}) => {
  const classes = useStyles();
  const isRelatedPartsParent = ![RELATED_PARTS_STATUS.RELATED_SEPARATE,
    RELATED_PARTS_STATUS.RELATED_TOGETHER].includes(parentStatus);

  return (
    <>
      {isRelatedPartsParent
      && <div className={classes.mainDiv}>
        <ParentPartSelector status={parentStatus} parentStatusChanged={parentStatusChanged}
          relatedPartsState={relatedPartsState} documentRevision={documentRevision} relatedType={relatedType} />
        {parentStatus === RELATED_PARTS_STATUS.PARENT_SEPARATE && !relatedPartsState.viewOnlyMode && (
          <Grid item xs={12}>
            <FBButton
              label={getReplacedMessage(
                'relatedParts.button.addText', '{relatedType}',
                getDynamicTextValue(relatedType, RELATED_TYPE_VALUES_ENUM.PART, RELATED_TYPE_VALUES_ENUM.DOCUMENT))}
              dataCy="parent-separate-add-related-parts-btn"
              onClick = {createNewDocument} />
          </Grid>
        )}
        {parentStatus === RELATED_PARTS_STATUS.PARENT_TOGETHER && (
          <RelatedPartGrid
            documentRevision={documentRevision}
            createNewDocument={createNewDocument}
            viewRelatedPart={viewRelatedPart}
            handleRemove={handleRemove}
            handleUpdate={handleUpdate}
            fetchRelatedParts = {fetchRelatedParts}
            voidRelatedPart = {voidRelatedPart}
            relatedPartsState = {relatedPartsState}
            relatedType = {relatedType}
          />
        )}
      </div>}
    </>
  );
};

export default RelatedParts;
