import cx from 'classnames';
import { useFormikContext } from 'formik';
import React, { ComponentType } from 'react';
import { FormikField, TextField } from '../../../../../components/forms/fields-next';
import { EditableBOM } from '../../../interface';
import { enableFieldAfterPartSelection, getCellValue, isEditBOM } from '../utils';
import { styles } from './styles';
import { CustomTreeListCellProps } from './types';

const QuantityCell: ComponentType<CustomTreeListCellProps> = ({
  dataItem,
  field,
  colSpan,
  style,
  className,
  hasChildren,
  ...otherProps
}) => {
  const classes = styles({ hasChildren });
  const { values, getFieldProps, setFieldValue } = useFormikContext<EditableBOM>();
  const isEditMode = isEditBOM(dataItem) && dataItem.enabledFieldsForEditing?.includes(field ?? '');

  const getFieldInfo = () =>
    field && (
      <FormikField name={field}>
        <TextField
          {...getFieldProps(field)}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            if (!isNaN(Number(val)) && Number(val) >= 0) { setFieldValue(field, val); }
          }}
          inputProps={{ min: 0 }}
          disabled={!enableFieldAfterPartSelection(dataItem, values.docId)}
          size="small"
        />
      </FormikField>
    );

  return (
    <td
      colSpan={colSpan}
      style={style}
      role="gridcell"
      className={cx('k-table-td', className, classes.cellborderBottom)}
      {...{
        'aria-colindex': otherProps.ariaColumnIndex,
        'data-grid-col-index': otherProps.colIndex,
        'aria-expanded': otherProps.expanded,
      }}
      data-cy="cell-quantity"
    >
      {isEditMode && getFieldInfo()}
      {!isEditMode && (
        <span className={classes.Cell}>
          {getCellValue(dataItem, field)}
        </span>
      )}
    </td>
  );
};

export default QuantityCell;
