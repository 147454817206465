import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { intl } from '../../../common/intl';
import { getTableCriteria } from '../../../common/utils/selectors';
import { tableSearchActions } from '../../../state/ducks/tableSearch';
import { trainingActions } from '../../../state/ducks/training';
import { TRAINING_RECORDS_SEARCH_URL } from '../../../state/ducks/training/constants';
import { DEFAULT_TRAINING_RECORD_QUERY, TrainingRecordQuery } from '../../../state/ducks/training/types';
import { getSchemaFromColumns } from '../../common/utils/helpers';
import { KendoColumn } from '../../components/common/kendo/types';
import { DataStateProps } from '../../components/KendoGrid/interfaces';
import { toastError } from '../../components/notifications';
import useActionCreator from '../../hooks/useActionCreator';
import useAsync from '../../hooks/useAsync';
import useDialog from '../../hooks/useDialog';
import TrainingDialog from '../details/TrainingDialog';
import gridConfiguration from './column.definition';
import { TrainingListPresenter } from './presenter';

export const TRAINING_TABLE_NAME = 'training';

const TrainingListContainer: React.FunctionComponent<RouteComponentProps> = () => {
  const schema = getSchemaFromColumns(gridConfiguration.columns);
  const dispatch = useDispatch();
  const tableCriteria = useSelector(getTableCriteria(TRAINING_TABLE_NAME));
  const location = useLocation();

  const setSearchStatus = (
    dataState: DataStateProps,
    columns: KendoColumn[],
  ) => {
    dispatch(
      tableSearchActions.setSearchCriteria(
        {
          ...tableCriteria,
          apiError: '',
          queryDict: {
            dataState,
          },
          columnConfig: JSON.stringify(columns),
        },
        TRAINING_TABLE_NAME,
      ),
    );
  };

  const trainingRecordDataById = useActionCreator(trainingActions.getTrainingRecordData);
  const async = useAsync({
    onSuccess: (data?: TrainingRecordQuery) => {
      if (!data) {
        return;
      }

      trainingDetailsDialog.open();
      setTraining(data);
    },
    onError: () => {
      toastError(intl.formatMessage({ id: 'common.training.released.state.doc.not.found' }));
    },
  });

  useEffect(() => {
    const pathname = location?.pathname;
    const params = pathname.split('/');
    const id = params[params.length - 1];
    if (!id.includes('training')) {
      async.start(trainingRecordDataById, id, async);
    }
  }, []);

  useEffect(() => {
    if (
      isEmpty(tableCriteria?.queryDict?.dataState?.filter?.filters)
      && isEmpty(tableCriteria?.queryDict?.dataState?.sort)
      && isEmpty(tableCriteria?.queryDict?.dataState?.group)
    ) {
      const dataState: DataStateProps = {
        filter: {
          logic: 'and',
          filters: [
            {
              field: 'trainingPlan.employee.active',
              operator: 'eq',
              value: true,
            },
          ],
        },
        sort: [],
        skip: 0,
        take: 25,
        group: [],
      };
      setSearchStatus(
        dataState,
        gridConfiguration.columns,
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trainingDetailsDialog = useDialog();
  const [training, setTraining] = useState<TrainingRecordQuery>(
    DEFAULT_TRAINING_RECORD_QUERY,
  );

  const onRowClick = (rowData: { dataItem: TrainingRecordQuery }) => {
    trainingDetailsDialog.open();
    setTraining(rowData.dataItem);
  };

  return (
    <>
      <TrainingListPresenter
        tableName={TRAINING_TABLE_NAME}
        tableCriteria={tableCriteria}
        schema={schema}
        columns={gridConfiguration.columns}
        onRowClick={onRowClick}
        queryUrl={TRAINING_RECORDS_SEARCH_URL}
        defaultPayloadData={{}}
      />
      {trainingDetailsDialog.isOpen && (
        <TrainingDialog
          dialog={trainingDetailsDialog}
          training={training}
        />
      )}
    </>
  );
};

export default TrainingListContainer;
