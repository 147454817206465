import { filter, map, uniqBy } from 'lodash';
import { action, computed, observable, set } from 'mobx';
import { FBAutocompleteAsyncOption, FBAutocompleteAsyncState, FBInlineApprovalBody, FBInlineApprovalConstructor, FBInlineApprovalOptions, FBInlineApprovalTransition, FBInlineApprovalTransitionBody, FBRequestApprovalTransitionBody } from '..';
import { Document } from '../../../state/ducks/documentRevisions/types';
import { FBEndpoint } from '../defaults/FBEndpoint';
import FBRequest from '../FBApi/FBApi.request';

class FBInlineApprovalState {
  // MARK: @observables
  @observable public options?: FBInlineApprovalOptions[];
  @observable approvals?: FBInlineApprovalBody[];

  // MARK: @config
  private isOwner?: boolean;

  // MARK: @api
  public approversApi = new FBAutocompleteAsyncState({ optionId: FBAutocompleteAsyncOption.availableApprovers });
  public groupsApi = new FBAutocompleteAsyncState({ optionId: FBAutocompleteAsyncOption.groups });
  public rolesApi = new FBAutocompleteAsyncState({ optionId: FBAutocompleteAsyncOption.approvalRoles });
  public approvalApi = new FBRequest<FBInlineApprovalBody, Partial<FBInlineApprovalBody>>(FBEndpoint.Approvals);
  public transitionApi =
  new FBRequest<FBInlineApprovalBody, FBInlineApprovalTransitionBody | null>(FBEndpoint.ApprovalsTransition);

  public requestApprovalApi =
  new FBRequest<FBInlineApprovalBody[], FBRequestApprovalTransitionBody | null>(FBEndpoint.ApprovalsTransition);

  public documentApi: FBRequest<Document, null> = new FBRequest(FBEndpoint.Document);

  // MARK: @constructor
  public constructor (props: FBInlineApprovalConstructor) {
    this.initialApprovals(props);
  }

  // MARK: @computed
  @computed public get getApprovals (): FBInlineApprovalBody[] {
    const filtered = filter(this.approvals, (a) => a.status !== 'ABANDONED');
    return map(filtered, (a: FBInlineApprovalBody) => ({
      ...a,
      canRequest: this.isOwner && a.status === 'DRAFT',
    }));
  }

  @computed public get getOptions (): FBInlineApprovalOptions[] {
    return uniqBy(this.options, 'user.id');
  }

  // MARK: @actions
  @action public setOptions = (options?: FBInlineApprovalOptions[]) =>
    set(this, 'options', options);

  @action public setApprovals = (approvals?: FBInlineApprovalBody[]) =>
    set(this, 'approvals', approvals);

  // MARK: @helpers
  public initialApprovals = ({ approvals, isOwner }: FBInlineApprovalConstructor) => {
    approvals = filter(approvals, (a) => a.status !== 'ABANDONED');
    this.setApprovals(approvals);
    this.isOwner = isOwner;
    const approvers = map(approvals, 'approver');
    const options = map(approvers, (o) => ({ ...o, listGroup: 'Approvers' })) as FBInlineApprovalOptions[];
    this.setOptions(options);
  };

  public approvalBody = (
    body: Partial<FBInlineApprovalBody>,
    callback?: () => any,
  ) => {
    this.approvalApi.set({
      url: FBEndpoint.Approvals,
      body,
      method: 'post',
    }, (data, error) => {
      if (error) {
        this.approvalApi.onError(error);
      } else {
        this.approvalApi.data = data;
        this.approvalApi.onSuccess();
        callback?.();
      }
    });
  };

  public approvalTransition = (
    transition: FBInlineApprovalTransition,
    id?: string,
    body?: FBInlineApprovalTransitionBody,
    callback?: () => void,
  ) => {
    if (!id) { return; }
    this.transitionApi.set({
      body,
      url: FBEndpoint.ApprovalsTransition,
      urlValues: { id, transition },
      method: 'post',
    }, (data, error) => {
      if (error) {
        return this.transitionApi.onError(error);
      }
      this.transitionApi.data = data;
      this.transitionApi.onSuccess();
      callback?.();
    });
  };

  public requestApprovalTransition = (
    transition: FBInlineApprovalTransition,
    id: string,
    body: FBRequestApprovalTransitionBody,
    callback?: () => void,
  ) => this.requestApprovalApi.set({
    body,
    url: FBEndpoint.ApprovalsTransition,
    urlValues: { id, transition },
    method: 'post',
  }, (data, error) => {
    if (error) {
      return this.requestApprovalApi.onError(error);
    }
    this.requestApprovalApi.data = data;
    this.requestApprovalApi.onSuccess();
    callback?.();
  });

  public getDocument = (id?: string) => {
    if (!id) { return; }
    this.documentApi.set({
      url: FBEndpoint.Document,
      urlValues: { id },
      method: 'get',
    });
  };
}

export default FBInlineApprovalState;
