import { Backdrop, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import isMobile from 'is-mobile';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReactComponent as DownloadPdfSolidIcon } from '../../../../assets/images/download_pdf_solid.svg';
import { attachmentsActions } from '../../../../state/ducks/attachments';
import { AttachmentDownloadResponse } from '../../../../state/ducks/attachments/types';
import { authSelectors } from '../../../../state/ducks/auth';
import { documentRevisionsActions } from '../../../../state/ducks/documentRevisions';
import ViewFileContent from '../../../components/common/view.file/components/dialog.content';
import { styles } from '../../../components/common/view.file/utils/styles';
import { toastError, toastInfo } from '../../../components/notifications';
import useActionCreator from '../../../hooks/useActionCreator';
import useAsync from '../../../hooks/useAsync';
import { DialogHandler } from '../../../hooks/useDialog';
import useMqttSubscription from '../../../hooks/useMqttSubscription';

interface Props {
  id: string
  dialogPDF: DialogHandler
  onClose?: () => void
  downloadPdfHover?: boolean
  downloadImmediately?: boolean
}

const DownloadHtmlPreview: React.FC<Props> = ({
  id,
  dialogPDF,
  onClose,
  downloadPdfHover = false,
  downloadImmediately = false,
}) => {
  const intl = useIntl();
  const employeeId = useSelector(authSelectors.currentEmployeeId);
  const [URL, setURL] = React.useState<string>('');
  const classes = styles({ isPDF: true });

  const handleClose = () => {
    dialogPDF.close();
    onClose?.();
    downloadPdfHover = false;
    downloadImmediately = false;
  };

  const templateAction = useActionCreator(documentRevisionsActions.getRenderTemplatePreview);
  const templateAsync = useAsync({
    onSuccess: () => {
      toastInfo(intl.formatMessage({ id: 'document.revision.download.html.request' }));
    },
    onError: () => {
      toastError(intl.formatMessage({ id: 'document.revision.download.pdf.error' }));
      handleClose();
    },
  });
  const handleClick = () => {
    templateAsync.start(templateAction, id, templateAsync);
    if (!downloadImmediately) {
      dialogPDF.open();
    }
  };

  const callback = React.useCallback((message: string) => {
    const json = JSON.parse(message).data.message;
    const { url } = JSON.parse(json);
    assetAsync.start(assetAction, url, assetAsync);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const topic = React.useMemo(() =>
    `${employeeId}/document_revisions/${id}/pdf`
  , [employeeId, id]);

  useMqttSubscription(topic, callback);

  const assetAction = useActionCreator(attachmentsActions.getAssetUrl);
  const assetAsync = useAsync<AttachmentDownloadResponse>({
    onSuccess: (downloadInfo) => {
      const url = downloadInfo?.url ?? '';
      if (downloadImmediately) {
        handleClose();
        window.location.href = url;
        return;
      }

      setURL(url);
    },
    onError: () => {
      toastError(intl.formatMessage({ id: 'document.revision.download.pdf.error' }));
      handleClose();
    },
  });
  useEffect(() => {
    if (downloadImmediately || downloadPdfHover) {
      handleClick();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Backdrop open={dialogPDF.isOpen} className={classes.backdrop}>
        {dialogPDF.isOpen && <Dialog
          onClose={handleClose}
          open={dialogPDF.isOpen}
          classes={{
            paper: classes.paper,
          }}
          maxWidth="lg"
          fullWidth={true}
          disableBackdropClick={true}
        >
          <DialogTitle>
            <IconButton
              data-cy="dialog-close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ViewFileContent
              asyncState={assetAsync.asyncState}
              url={URL}
              type="PDF"
              handleClose={handleClose}
            />
          </DialogContent>
        </Dialog>}
      </Backdrop>
      {downloadPdfHover && !isMobile() && (
        <DownloadPdfSolidIcon
          data-cy="download-pdf"
          onClick={handleClick}
        />
      )}
    </>
  );
};
export default DownloadHtmlPreview;
