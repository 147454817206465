import { compact, Dictionary, get, isArray, isDate, isObject, join, map } from 'lodash';
import React from 'react';
import { FBValidationProps } from '..';

export const withFBValidation = <T extends FBValidationProps>(
  Component: React.FunctionComponent<T>,
) => {
  const Comp = ({
    toStringRules,
    ...props
  }: T) => {
    function valueByType (value: any, key: string, validator: string): any {
      switch (true) {
        case isArray(value): return validator === 'required_if_in' ? JSON.stringify({ ...value }) : value;
        case isDate(value): return value;
        case isObject(value): return get(value, key);
        default: return value;
      }
    }

    function valueSplit (key: string, value: any): string {
      const ruleDelimiter = [':', ','];
      let rules = value ? key : '';
      if (isObject(value)) {
        map(value, (v, k) => {
          rules += `${ruleDelimiter.shift()}${valueByType(v, k, key)}`;
        });
      }
      return rules;
    }

    toStringRules = (validators: Dictionary<any>): string | undefined => {
      const rules = map(validators, (value, key) => `${valueSplit(key, value)}`);
      return join(compact(rules), '|');
    };

    return Component({
      ...(props as T),
      toStringRules,
    });
  };

  return (props: T) => Comp(props);
};
