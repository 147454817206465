export const dropDownOptions = [
  { value: 'TIME_TRAVEL_USER_SESSION', text: 'TIME_TRAVEL_USER_SESSION' },
  { value: 'TIME_TRAVEL_USER', text: 'TIME_TRAVEL_USER' },
  { value: 'TIME_TRAVEL_COMPANY', text: 'TIME_TRAVEL_COMPANY' },
  {
    value: 'UPDATE_USERS_AND_TEST_PASSWORD_EXPIRE',
    text: 'UPDATE_USERS_AND_TEST_PASSWORD_EXPIRE',
  },
  {
    value: 'RECALC_COMPLIANCE_FOR_ON_EQ_RELEASE',
    text: 'RECALC_COMPLIANCE_FOR_ON_EQ_RELEASE',
  },
  {
    value: 'RECALC_COMPLIANCE_FOR_EQUIPMENTS',
    text: 'RECALC_COMPLIANCE_FOR_EQUIPMENTS',
  },
  {
    value: 'SEND_EMAILS_FOR_UPCOMING_DUE_DATES_IN_EQS',
    text: 'SEND_EMAILS_FOR_UPCOMING_DUE_DATES_IN_EQS',
  },
  {
    value: 'SEND_EMAILS_FOR_TRAINING_RECORDS_DUE_DATES',
    text: 'SEND_EMAILS_FOR_TRAINING_RECORDS_DUE_DATES',
  },
  {
    value: 'SEND_PASSWORD_EXPIRATION_EMAILS_SPECIFIC',
    text: 'SEND_PASSWORD_EXPIRATION_EMAILS_SPECIFIC',
  },
  {
    value: 'RECALC_GROUP_TO_EMPLOYEE_ALL_COMPANIES',
    text: 'RECALC_GROUP_TO_EMPLOYEE_ALL_COMPANIES',
  },
  { value: 'TRAINING_RECORDS_REFRESH', text: 'TRAINING_RECORDS_REFRESH' },
  { value: 'TRAINING_RECORDS_STATUS', text: 'TRAINING_RECORDS_STATUS' },
  { value: 'UPDATE_BILLS', text: 'UPDATE_BILLS' },
  {
    value: 'DELETE_COMPANIES_SCHDEULED_FOR_DELETE',
    text: 'DELETE_COMPANIES_SCHDEULED_FOR_DELETE',
  },
  {
    value: 'PRECALC_DOC_TYPE_FOR_COMPANY',
    text: 'PRECALC_DOC_TYPE_FOR_COMPANY',
  },
  { value: 'FIXER_RETRIEVE_CURRENCIES', text: 'FIXER_RETRIEVE_CURRENCIES' },
  {
    value: 'SEND_COMPANY_CONFIG_DIFF_TO_EMAILS',
    text: 'SEND_COMPANY_CONFIG_DIFF_TO_EMAILS',
  },
  { value: 'GENERATE_COMPANY_CONFIGS', text: 'GENERATE_COMPANY_CONFIGS' },
  { value: 'CLOSE_CR_BY_EFFECTIVE_DATE', text: 'CLOSE_CR_BY_EFFECTIVE_DATE' },
  { value: 'USAGE_REPORT', text: 'USAGE_REPORT' },
  {
    value: 'SEND_PASSWORD_EXPIRATION_EMAILS',
    text: 'SEND_PASSWORD_EXPIRATION_EMAILS',
  },
  { value: 'PRECALC_PAGE_DETAILS', text: 'PRECALC_PAGE_DETAILS' },
  { value: 'UNLOCK_USER_AND_SET_DEFAULT_PASSWORD', text: 'UNLOCK_USER_AND_SET_DEFAULT_PASSWORD' },
];

export const AsyncStatus = [
  { value: 'IN_PROGRESS', text: 'IN_PROGRESS' },
  { value: 'REQUESTED', text: 'REQUESTED' },
  { value: 'COMPLETE', text: 'COMPLETE' },
  { value: 'ERROR', text: 'ERROR' },
];
