import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from '../../../../state/ducks/auth';
import { bkStatusTextMap } from '../../../../state/ducks/bulkImport/constants';
import { getBulkImportsGroupByProcess } from '../../../../state/ducks/bulkImport/selectors';
import { BulkImportCustom } from '../../../../state/ducks/bulkImport/types';
import ProgressItem from './ProgressItem';
import useStyles from './styles';

const ProgressSection: React.FC = () => {
  const classes = useStyles();
  const bulkImportsGroupByProcess = useSelector(getBulkImportsGroupByProcess);
  const currentEmployeeId = useSelector(authSelectors.currentEmployeeId);

  return (
    <Box>
      {Object.keys(bulkImportsGroupByProcess).map((key: string) => {
        return (
          <Box key={key}>
            <Typography className={classes.progressTitle}>
              <FontAwesomeIcon
                className={classes.expandIcon}
                icon={solid('chevron-down')}
              />{bkStatusTextMap[key] || key}
            </Typography>
            {bulkImportsGroupByProcess[key]?.map((item: BulkImportCustom, idx: number) => {
              return (
                <ProgressItem item={item} key={`${key}-${idx}`} valKey={key} idx={idx} currentEmployeeId={currentEmployeeId} />
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default ProgressSection;
